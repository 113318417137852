import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import BlockContent from "../components/block-content";
import Layout from "../components/layout.js";

export const query = graphql`
  query AboutPage {
    sanityPage(_id: { eq: "106317fc-246c-425d-8cc6-cd12860e8c6c" }) {
      _id
      title
      description
      _rawBody
    }
    allSanityPartner {
      nodes {
        id
        name
        href
        _rawInfo
        logo {
          asset {
            id
            gatsbyImageData(width: 350)
          }
        }
      }
    }
  }
`;

export default function AboutPage({ data }) {
  const page = data.sanityPage;
  const partners = data.allSanityPartner.nodes;

  return (
    <Layout title={page.title || " "} description={page.description}>
      <BlockContent blocks={page._rawBody || []} />

      <div class="heading-container">
        <h2>Partners</h2>
        <div class="heading-line"></div>
      </div>

      {partners.map((partner) => (
        <div className="partner" key={partner.id}>
          <BlockContent blocks={partner._rawInfo || []} />
          {
            //Check for image and URL
            partner.logo !== null &&
            partner.logo.asset &&
            partner.href !== null ? (
              <a href={partner.href}>
                <GatsbyImage
                  image={partner.logo.asset.gatsbyImageData}
                  alt={partner.title}
                />
              </a>
            ) : null
          }
          {
            //Check for image without URL
            partner.logo !== null &&
            partner.logo.asset &&
            partner.href === null ? (
              <GatsbyImage
                image={partner.logo.asset.gatsbyImageData}
                alt={partner.title}
              />
            ) : null
          }
        </div>
      ))}
    </Layout>
  );
}
